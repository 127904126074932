.popup-form {
  max-width: 1050px;
  background: $grey2;
  border-radius: 20px;

  @include media-breakpoint-down(lg) {
    max-width: 80%;
  }

  @include media-breakpoint-down(md) {
    max-width: 490px;
  }

  @include media-breakpoint-down(xs) {
    padding: 40px 15px;
  }

  h1 {
    margin-bottom: 30px;
    font-size: 58px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
      font-size: 42px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      font-size: 28px;
    }
  }
}
