.slider-recipes {
  margin-top: 203px;
  padding-bottom: 60px;

  @include media-breakpoint-down(lg) {
    margin-top: 123px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 96px;
    padding-bottom: 52px;
  }

  &--search {
    margin-top: 0 !important;
  }

  .tns-nav {
    margin-top: 30px;
  }
}
