.slider-news {
  margin-top: 203px;
  padding-bottom: 52px;

  @include media-breakpoint-down(lg) {
    margin-top: 123px;
    padding-bottom: 62px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 96px;
    padding-bottom: 52px;
  }

  &--search {
    margin-top: 0 !important;
  }

  .h1 {
    margin-bottom: 34px;
    font-size: 42px;
    line-height: 100%;

    @include media-breakpoint-down(lg) {
      margin-bottom: 26px;
      font-size: 36px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  .tns-nav {
    margin-top: 30px;
  }
}
