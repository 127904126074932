.front {
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 188px;
    content: '';
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }

  &__item {
    position: relative;
    height: 100vh;
    min-height: 740px;

    @include media-breakpoint-down(lg) {
      min-height: 600px;
    }

    @include media-breakpoint-down(md) {
      height: auto;
      min-height: 480px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 480px;
    }

    &:before {

      @include media-breakpoint-down(md) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background: radial-gradient(circle, rgba(83, 62, 40, .15) 0%, rgba(255, 255, 255, 0) 100%),
          radial-gradient(circle, rgba(0, 0, 0, .15) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    picture {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;

        @include media-breakpoint-down(md) {
          object-position: left bottom;
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 140px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(83, 62, 40, .15) 0%, rgba(255, 255, 255, 0) 100%),
      radial-gradient(circle, rgba(0, 0, 0, .15) 0%, rgba(255, 255, 255, 0) 100%);

    @include media-breakpoint-down(xl) {
      padding-top: 0;
      padding-bottom: 150px;
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: 150px;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      z-index: 1;
      padding-top: 120px;
      padding-bottom: 0;
      background: transparent;
    }
  }

  &__content-wrapper {
  }

  &__head {
    margin-bottom: 15px;
    font-size: 60px;
    line-height: 69px;
    text-align: center;
    color: $white;

    @include media-breakpoint-down(xl) {
      margin-bottom: 26px;
      font-size: 45px;
      line-height: 53px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 14px;
      font-size: 32px;
      line-height: 37px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 140px;
    line-height: 152px;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    @include media-breakpoint-down(xl) {
      margin-bottom: 8px;
      font-size: 107px;
      line-height: 117px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 4px;
      font-size: 68px;
      line-height: 74px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 58px;
      line-height: 62px;
    }

    span {

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__text {
    font-family: $font-secondary;
    font-size: 31px;
    line-height: 1.3;
    text-align: center;
    color: $white;

    @include media-breakpoint-down(xl) {
      font-size: 24px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }
  }
}
