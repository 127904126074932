.card-video {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 260px;
  border-radius: 20px;

  @include transition;

  @include media-breakpoint-down(lg) {
    height: 197px;
    border-radius: 10px;
  }

  @include media-breakpoint-down(sm) {
    height: 184px;
  }

  &:before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f4f3f2 100%);

    @include transition;
  }

  &:after {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    content: '';
    background: linear-gradient(180deg, #f4f5f8 0%, rgba(244, 245, 248, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);

    @include transition;
  }

  &:hover {

    &:after {
      opacity: 0;
    }
  }

  &--lg {
    height: 314px;

    &:after {
      display: none;
    }

    &:hover {

      &:before {
        opacity: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      height: 216px;
    }

    @include media-breakpoint-down(sm) {
      height: 180px;
      border-radius: 20px;
    }

    video {

      @include media-breakpoint-down(sm) {
        border-radius: 22px;
      }
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 22px;

    @include media-breakpoint-down(lg) {
      border-radius: 12px;
    }
  }

  &__text {
    z-index: 2;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      padding-top: 96px;
      padding-right: 13px;
      padding-left: 13px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 69px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-bottom: 15px;
      justify-content: flex-end;
    }
  }

  &__title {
    margin-bottom: 13px;
    font-size: 38px;
    line-height: 100%;
    font-weight: 300;
    color: $dark;

    @include transition;

    @include media-breakpoint-up(xl) {
      font-size: 42px;
      line-height: 46px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 8px;
      font-size: 28px;
    }
  }

  &__desc {
    width: 100%;
    max-width: 280px;
    font-family: $font-secondary;
    font-size: 15px;
    line-height: 21px;
    color: $grey3;

    @include transition;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
