.section-recipe {

  &__head {
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey2;
    border-radius: 14px;

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    @include media-breakpoint-down(xs) {
      padding: 10px;
      justify-content: flex-start;
      flex-flow: row wrap;
    }
  }

  &__pr-img {
    width: 36px;
  }

  &__pr {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      padding: 10px;
      width: 50%;
    }

    span {
      margin-left: 15px;
      font-family: $font-secondary;
      font-size: 22px;
      font-weight: 300;
      color: $dark;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      @include media-breakpoint-down(xs) {
        margin-left: 5px;
      }
    }
  }

  &__tags {
    padding: 30px 0;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      padding: 15px 0;
    }

    span {
      margin-right: 30px;
      font-size: 16px;
      color: $secondary;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__img {
    margin-bottom: 54px;
    border-radius: 14px;

    @include media-breakpoint-down(md) {
      margin-bottom: 44px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  &__prop {
    margin-bottom: 15px;
    display: flex;
    font-family: $font-secondary;
    font-size: 18px;
    font-weight: 400;
    color: $dark;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      display: flex;
      flex-grow: 1;
      font-weight: 300;

      &:after {
        position: relative;
        top: -.3em;
        margin: 0 5px;
        display: inline-block;
        flex-grow: 1;
        content: '';
        //border-bottom: 1px dotted #000000;
        background: url(../img/line-dots.svg) repeat-x bottom left;
      }
    }

    a {
      font-weight: 600;
      color: $primary;

      @include transition;

      &:hover {
        color: $primary-hover;
      }
    }

    &--cheese {

      @include media-breakpoint-down(sm) {
        padding-top: 40px;
        padding-left: 15px;
        display: flex;
        width: 50%;
        flex-direction: column;
        align-self: flex-start;
      }

      span {

        @include media-breakpoint-down(sm) {
          margin-bottom: 9px;
          font-family: $font-secondary;
          font-size: 14px;
          color: $secondary;
        }
      }

      a {

        @include media-breakpoint-down(sm) {
          font-size: 20px;
          line-height: 1;
          font-weight: 300;
          color: $primary;

          @include transition;
        }

        &:hover {
          color: $primary-hover;
        }
      }
    }
  }

  &__prop-img {
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-top: 15px;
      display: flex;
      align-items: center;
      text-align: left;
    }

    img {

      @include media-breakpoint-down(sm) {
        padding-right: 15px;
        width: 50%;
      }
    }
  }

  &__text {
    padding-top: 66px;
    padding-bottom: 66px;
    border-top: 1px solid $light2;
    border-bottom: 1px solid $light2;

    @include media-breakpoint-down(md) {
      padding-top: 70px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 16px;
      display: flex;
      align-items: stretch;
      font-family: $font-secondary;
      font-size: 18px;
      line-height: 1.4;
      font-weight: 300;
      color: $dark;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      span {
        margin-right: 38px;
        display: flex;
        width: 42px;
        min-width: 42px;
        height: 42px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 300;
        color: rgba($dark, .4);
        background: $white;
        border-radius: 100%;
        box-shadow: 0 15px 20px rgba(222, 227, 233, .4);

        @include media-breakpoint-down(md) {
          margin-right: 22px;
        }
      }
    }
  }
}
