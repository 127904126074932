.map-addresses {
  position: relative;
  margin-bottom: 105px;
  overflow: hidden;
  min-height: 410px;
  border-radius: 14px;

  @include media-breakpoint-down(sm) {
    min-height: 240px;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
}

.ymaps-2-1-78-placemark-overlay {

  & > ymaps {
    top: -16px !important;
    left: -16px !important;
    width: 32px !important;
    height: 32px !important;
    background: $primary !important;
    border-radius: 50%;

    .ymaps-2-1-78-svg-icon-content {
      top: 8px !important;
      width: 32px !important;
      font-size: 14px !important;
    }
  }
}
