.card-news {
  height: 100%;

  &--last {
    display: block;

    .card-news__img {
      position: relative;
      margin-bottom: 0;
      height: 100%;
      border-radius: 10px;

      @include media-breakpoint-down(xs) {
        min-height: 393px;
      }

      &:after {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%),
          linear-gradient(0deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
      }
    }

    .card-news__desc {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      padding: 0 70px 47px 30px;

      @include media-breakpoint-down(md) {
        padding: 0 20px 23px;
      }
    }

    .card-news__date {
      margin-bottom: 17px;
      color: $body-bg;
    }

    .card-news__title {
      overflow: hidden;
      min-height: auto;
      max-height: none;
      font-size: 27px;
      line-height: 32px;
      font-weight: 400;
      color: $body-bg;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .card-news__text {
      line-height: 18px;
      color: rgba($body-bg, .5);

      @include media-breakpoint-down(xs) {
        min-height: 69px;
        max-height: 69px;
      }
    }
  }

  &--lenta {
    padding: 65px 25px 42px 37px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $grey2;
    border-radius: 14px;

    @include media-breakpoint-down(md) {
      padding-bottom: 89px;
    }

    @include media-breakpoint-down(xs) {
      padding: 67px 28px 47px;
      min-height: 339px;
    }
  }

  &--line {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    .card-news__img {
      margin-right: 32px;
      margin-bottom: 0;
      width: 100%;
      max-width: 300px;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
        margin-bottom: 18px;
        max-width: 100%;
      }
    }

    .card-news__title {
      margin-bottom: 4px;
      overflow: hidden;
      min-height: 90px;
      max-height: 90px;
      font-size: 25px;
      line-height: 30px;

      @include media-breakpoint-down(lg) {
        min-height: 44px;
        max-height: 44px;
        font-size: 20px;
        line-height: 22px;
      }
    }

    .card-news__desc {
      padding-bottom: 0;
      flex: 1;
    }
  }

  &__img {
    position: relative;
    margin-bottom: 18px;
    display: block;
    overflow: hidden;
    min-height: 187px;
    border-radius: 14px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__label {
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 32px;
    padding: 0 14px;
    display: flex;
    height: 25px;
    align-items: center;
    font-family: $font-secondary;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: $body-bg;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 4px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__desc {
    padding-bottom: 21px;

    @include media-breakpoint-down(md) {
      padding-bottom: 25px;
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 13px;
    display: inline-block;
    overflow: hidden;
    min-height: 78px;
    max-height: 78px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin-bottom: 7px;
      min-height: 44px;
      max-height: 44px;
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__date {
    margin-bottom: 7px;
    font-family: $font-secondary;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: $light-grey;
  }

  &__text {
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 20px;
    color: $grey3;

    @include media-breakpoint-down(md) {
      overflow: hidden;
      min-height: 60px;
      max-height: 60px;
    }
  }

  &__blockquote {
    position: relative;
    font-size: 25px;
    line-height: 30px;

    &:before {
      position: absolute;
      top: -33px;
      left: 6px;
      width: 24px;
      height: 15px;
      content: '';
      background: url("data:image/svg+xml,%3Csvg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.2'%3E%3Cpath d='M18.5811 0C15.5169 0 13.0974 2.48442 13.0974 5.54865C13.0974 8.61288 15.6141 11.0973 18.6783 11.0973C18.7089 11.0973 18.7552 11.0931 18.7858 11.0926C17.5743 12.0574 16.0525 12.6388 14.3869 12.6388C13.6206 12.6388 13.0039 13.2601 13.0039 14.0259C13.0039 14.7917 13.4969 15.4131 14.2633 15.4131C19.7023 15.4126 23.9999 10.9877 23.9999 5.54865C23.9999 5.54761 23.9999 5.54657 23.9999 5.54502C23.9999 2.48235 21.6443 0 18.5811 0Z' fill='%2343464A'/%3E%3Cpath d='M5.58502 0C2.52079 0 0.0971534 2.48442 0.0971534 5.54865C0.0971534 8.61288 2.61171 11.0973 5.67646 11.0973C5.70711 11.0973 5.75231 11.0931 5.78296 11.0926C4.5714 12.0574 3.04916 12.6388 1.38353 12.6388C0.61721 12.6388 0 13.2601 0 14.0259C0 14.7917 0.500833 15.4131 1.26715 15.4131C6.70618 15.4126 11.0116 10.9877 11.0116 5.54865C11.0116 5.54761 11.0116 5.54657 11.0116 5.54502C11.0116 2.48235 8.64821 0 5.58502 0Z' fill='%2343464A'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
    }
  }

  &__rs {
    margin-top: 20px;
  }
}
