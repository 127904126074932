.front-page {
  position: relative;
  padding: 0 15px;
  text-align: center;
  color: $white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 30px;

  @include media-breakpoint-up(xl) {
    margin-top: -18px;
  }

  @include media-breakpoint-down(md) {
    border-radius: 10px;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .2;
    content: '';
    background: #24150d;
    border-radius: 30px;

    @include media-breakpoint-down(md) {
      opacity: .5;
      border-radius: 10px;
    }

    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  h1 {
    margin-bottom: 40px;
    font-size: 58px;
    line-height: 1;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
      font-size: 36px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  &__container {

    @include media-breakpoint-down(xs) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__text {
    display: inline-block;
    width: 550px;

    @include media-breakpoint-down(md) {
      width: 400px;
    }

    @include media-breakpoint-down(xs) {
      width: auto;
    }

    .h3 {
      margin-bottom: 60px;
      font-family: $btn-font-family;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
}
