.circle {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 400px;

  @media screen and (max-width: 1280px) {
    width: 300px;
  }

  @include media-breakpoint-down(lg) {
    position: relative;
    margin-top: -20px;
    margin-bottom: -50px;
    padding-top: 50px;
    width: 100%;
    height: 250px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 10px;
    margin-bottom: 0;
    height: 170px;
  }

  &__icon {
    display: flex;
    width: 82px;
    height: 82px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 4.72941px 40.2px rgba(247, 173, 92, .1);

    img {
      transition: $transition-base;
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    right: 100%;
    padding-right: 20px;
    flex-shrink: 0;
    opacity: .3;
    font-family: $font-secondary;
    font-size: 24px;
    line-height: 1.1;
    font-weight: 300;
    text-align: right;
    color: #2b2b2b;
    transform: translateY(-50%);

    @include media-breakpoint-down(lg) {
      top: 100%;
      right: auto;
      left: 50%;
      padding-top: 10px;
      padding-right: 0;
      opacity: 0;
      transition: $transition-base;
      transform: translateX(-50%);
    }
  }

  &__ring {
    position: relative;
    margin-left: 200px;
    width: 725px;
    height: 725px;
    border: 1px dashed rgba(#000, .2);
    border-radius: 50%;

    @include media-breakpoint-down(lg) {
      left: 50%;
      margin-left: -500px;
      width: 1000px;
      height: 1000px;
      border-color: rgba(#000000, .3);
      transform: rotate(90deg) scaleY(1.5);
      transform-origin: center center;
    }

    @include media-breakpoint-down(md) {
      transform: rotate(90deg) scaleY(1.25);
    }

    @include media-breakpoint-down(sm) {
      margin-left: -400px;
      width: 800px;
      height: 800px;
      transform: rotate(90deg);
    }

    @include media-breakpoint-down(xs) {
      margin-left: -245px;
      width: 490px;
      height: 490px;
    }
  }

  &__type {
    transition: $transition-base;
    transform: scale(.8);
    transform-origin: center center;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      transform: rotate(-90deg);
    }

    @include media-breakpoint-down(sm) {
      transform: rotate(-90deg) scale(.8);
    }

    .circle__icon img {
      opacity: .3;
      filter: grayscale(1) brightness(20%);
    }

    &.is-active {
      transform: none;

      @include media-breakpoint-down(lg) {
        transform: rotate(-90deg);
      }

      @include media-breakpoint-down(sm) {
        transform: rotate(-90deg);
      }

      .circle__icon img {
        opacity: 1;
        filter: none;
      }

      .circle__title {
        opacity: 1;
        color: #43464a;
      }
    }
  }

  &__item {
    position: absolute;
    margin-top: -41px;
    transition: $transition-base;

    @include media-breakpoint-only(lg) {
      margin-left: 15px;
      transform: scale(.76, .51);
      transform-origin: center center;
    }

    @include media-breakpoint-only(md) {
      margin-left: 15px;
      transform: scale(.76, .608);
    }

    @include media-breakpoint-down(xs) {
      margin-left: -15px;
    }

    &.is-top {
      top: 29%;
      left: -1%;
    }

    &.is-center {
      top: 50%;
      left: -5.7%;

      @include media-breakpoint-only(lg) {
        transform: scale(1, .668);
      }

      @include media-breakpoint-only(md) {
        transform: scale(1, .8);
      }
    }

    &.is-down {
      top: 72%;
      left: -1%;
    }

    &.item-down {

      @include media-breakpoint-up(md) {
      }

      animation-name: item-down;
      animation-duration: .3s;
      animation-timing-function: cubic-bezier(.25, .1, .25, 1);
      animation-fill-mode: both;
    }

    &.item-indown {
      animation-name: item-indown;
      animation-duration: .3s;
      animation-timing-function: cubic-bezier(.25, .1, .25, 1);
      animation-fill-mode: both;
    }

    &.item-top {
      animation-name: item-top;
      animation-duration: .3s;
      animation-timing-function: cubic-bezier(.25, .1, .25, 1);
      animation-fill-mode: both;
    }

    &.item-outtop {
      animation-name: item-outtop;
      animation-duration: .3s;
      animation-timing-function: cubic-bezier(.25, .1, .25, 1);
      animation-fill-mode: both;
    }
  }
}

@keyframes item-down {

  from {
    opacity: 1;
  }

  to {
    top: 93%;
    left: 21%;
    opacity: 0;
  }
}

@keyframes item-top {

  from {
    top: 7%;
    left: 21%;
  }

  to {
    top: 29%;
    left: -1%;
  }
}

@keyframes item-outtop {

  from {
    top: 29%;
    left: -1%;
    opacity: 1;
  }

  to {
    top: 7%;
    left: 21%;
    opacity: 0;
  }
}

@keyframes item-indown {

  from {
    top: 93%;
    left: 21%;
  }

  to {
    top: 72%;
    left: -1%;
  }
}
