.breadcrumbs {
  position: relative;
  z-index: 1;
  padding: 30px 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  @include media-breakpoint-down(md) {
    padding-bottom: 14px;
  }

  &--front-page {
    padding: 48px 0 22px;

    @include media-breakpoint-down(md) {
      padding: 30px 0 14px;
    }

    .breadcrumbs__item {
      color: rgba($white, .5);

      &:after {
        color: $white;
      }

      a {
        color: $white;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $grey6;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &:after {
      margin: 0 10px;
      content: '·';
      color: $grey3;
    }

    a {
      font-size: 12px;
      color: $grey3;

      @include transition;

      &:hover {
        color: $primary;
      }
    }

    &:last-of-type {

      &:after {
        display: none;
      }
    }
  }
}
