.filters {
  margin-top: -18px;
  padding: 0 80px 50px;
  background: $grey2;
  border-radius: 14px;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }

  @include media-breakpoint-down(md) {
    padding: 0 30px 35px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 15px 35px;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 0;
  }

  h1 {
    margin-bottom: 40px;
    font-size: 58px;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 28px;
      font-size: 42px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
      font-size: 36px;
    }
  }

  &__type {
    margin-bottom: 5px;
    font-family: $font-secondary;
    font-size: 16px;
    color: $secondary;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}
