.menu {
  display: none;

  &__main {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;

    li {
      position: relative;
      margin-right: 80px;

      &:nth-of-type(3) {
        margin-right: 0;
      }

      &:nth-of-type(4) {
        margin-right: 65px;
        margin-left: auto;
      }

      &:nth-of-type(5) {
        margin-right: 65px;
      }

      &:last-of-type {
        margin-right: 81px;
      }

      &:hover > .menu__drop {

        @include media-breakpoint-up(xl) {
          display: block;
        }
      }
    }

    a {
      padding: 42px 0 65px;
      display: inline-block;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 19px;

      @include transition;
    }
  }

  &__drop {
    position: absolute;
    z-index: 1;
    top: 81px;
    left: 0;
    margin: 0;
    padding: 5px 0;
    display: none;
    width: 250px;
    list-style-type: none;
    background: #fff;
    border: 1px solid rgba(143, 147, 153, .2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(67, 70, 74, .15);

    &:before {
      position: absolute;
      top: -8px;
      left: 32px;
      width: 35px;
      height: 8px;
      content: '';
      background: transparent url(../img/subnav-angle.png) 0 0 no-repeat;
    }

    li {
      margin-right: 0;
      border-bottom: 1px solid $light;

      &:last-of-type {
        margin-right: 0;
        border: 0 none;
      }

      &:nth-of-type(4) {
        margin-right: 0;
        margin-left: 0;
      }
    }

    a {
      padding: 15px 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        display: block;
        width: 5px;
        height: 9px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 4.5L0 9L0 0L4.5 4.5Z' fill='%23C7CCD1'/%3E%3C/svg%3E");
      }

      &:hover {

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 4.5L0 9L0 0L4.5 4.5Z' fill='%230057A5'/%3E%3C/svg%3E");
        }
      }
    }

    .menu__drop {
      top: -6px;
      left: 248px;
      margin-left: 20px;
      display: none;

      &:before {
        position: absolute;
        top: 0;
        left: -21px;
        width: 20px;
        height: 100%;
        content: '';
        background: transparent;
      }

      a:after {
        display: none;
      }
    }
  }

  &__head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__soc {
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    .btn-soc {
      margin: 0 10px;
      color: #0057a5;
      box-shadow: none !important;

      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
        line-height: 80px;
      }

      &:hover {
        color: #dee3e9;
      }
    }

    .btn-soc svg {

      @include media-breakpoint-up(md) {
        width: 32px;
        height: 19px;
      }
    }

    .btn-soc:nth-of-type(2) {

      svg {

        @include media-breakpoint-up(md) {
          width: 16px;
          height: 32px;
        }
      }
    }

    .btn-soc:nth-of-type(3) {

      svg {

        @include media-breakpoint-up(md) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &__tel {
    margin-bottom: 60px;
    display: block;
    font-size: 58px;
    line-height: 1;
    font-weight: 300;
    text-align: center;
    color: $white;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      font-size: 28px;
    }

    &:hover {
      color: $white;
    }
  }

  &__copyright {
    margin-bottom: 40px;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 300;
    text-align: center;
    color: #dee3e9;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      font-size: 13px;
    }
  }
}
