.map-contacts {

  @include media-breakpoint-down(xs) {
    margin-right: -15px;
    margin-left: -15px;
  }

  &__map-place {
    overflow: hidden;
    width: 965px;
    border-radius: 14px;

    @include media-breakpoint-down(lg) {
      width: 640px;
    }

    @include media-breakpoint-down(md) {
      width: 435px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      width: 100%;
      height: 410px;
    }

    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
}
