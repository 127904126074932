h1,
.h1 {
  font-size: 46px;
  line-height: 1;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 32px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
  }
}

.h1-lg {
  font-size: 58px;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 42px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
  }
}

.h1-md {
  font-size: 52px;
  line-height: 1.1;
  color: $dark;

  @include media-breakpoint-down(md) {
    font-size: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 34px;
  }
}

.h1-sm {
  font-size: 42px;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
}

.h1-xs {
  font-size: 42px;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

.h1--mob-sm {

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 1;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 38px;
    line-height: 1.2;
  }

  @include media-breakpoint-down(sm) {
    line-height: 1;
  }
}

h3,
.h3 {
  font-size: 30px;
  line-height: 1.3;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 26px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 1;
  }
}

h4,
.h4 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: normal;

  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 1.3;
  }

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 1;
  }
}

h5,
.h5 {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 22px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h6,
.h6 {
  font-size: 20px;
  line-height: 1.1;
  font-weight: 500;
}

p,
.p {
  font-size: 14px;
  line-height: 1.4;

  &::selection {
    background: #dee3e9;
  }
}

.p-xs {
  font-size: 13px;
  line-height: 1.4;
}

.p-sm {
  font-size: 15px;
  line-height: 1.4;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.p-md {
  font-size: 16px;
  line-height: 1.4;

  &::selection {
    background: #dee3e9;
  }
}

.p-lg {
  font-size: 18px;
  line-height: 1.4;

  &::selection {
    background: #dee3e9;
  }

  &.font-inter {
    margin-bottom: 25px;
    line-height: 1.5;
  }
}

.p-secondary {
  font-size: 14px;
  line-height: 1.31;
  color: $secondary;

  &::selection {
    background: #dee3e9;
  }
}

.text-secondary {
  color: #747d85 !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-black {
  font-weight: 900;
}

.font-weight-semi {
  font-weight: 600 !important;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-andant {
  font-family: 'Andantino script', sans-serif;
}

.bg-grey2 {
  background: $grey2;
}

.overflow-hidden {
  overflow: hidden;
}

ul {

  li {
  }
}

ol {

  li {
  }
}

table {
}

tr {
}

th {
}

td {
}

a {
}

input {

  &:focus {
    outline: none;

    @include placeholder(transparent);
  }
}

textarea {

  &:focus {
    outline: none;
  }
}

select {
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input[type='submit'] {
  border: 0;
  cursor: pointer;
}

img {
  max-width: 100%;
}
