.section-cards {
  padding: 20px 0 100px;
  background: $grey7;

  @include media-breakpoint-down(lg) {
    padding: 40px 0 100px;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 0 80px;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0 60px;
  }

  h2 {
    margin-bottom: 40px;
    font-size: 42px;
    line-height: 1.1;
    text-align: center;
    color: $dark;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 1;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  &__item {
    padding-bottom: 30px;
    max-width: 660px;
  }

  .tns-slider {
    display: flex;

    .section-cards__item {
      display: flex;
    }

    .card-slide {
      width: 100%;
    }

    .card-slide__desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-grow: 1;
    }
  }
}
