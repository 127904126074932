.mouse {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 50%;
  display: flex;
  width: 54px;
  height: 69px;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 30px;
  transform: translateX(-50%);
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    bottom: 40px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  &:after {
    display: block;
    width: 16px;
    height: 27px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='27' viewBox='0 0 16 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' fill-rule='evenodd' clip-rule='evenodd' d='M0 7.94188C0 3.56266 3.57854 0 7.97727 0C12.376 0 15.9545 3.56266 15.9545 7.94188V19.0581C15.9545 23.4373 12.376 27 7.97727 27C3.57854 27 0 23.4373 0 19.0581V7.94188ZM7.97727 25.3801C11.4786 25.3801 14.3274 22.5439 14.3274 19.0581V7.94188C14.3274 4.45605 11.4786 1.61993 7.97727 1.61993C4.47591 1.61993 1.62715 4.45605 1.62715 7.94188V19.0581C1.62715 22.5439 4.47591 25.3801 7.97727 25.3801ZM7.22689 6.14442C7.22689 5.68808 7.59312 5.31831 8.04507 5.31831C8.49703 5.31831 8.86325 5.68808 8.86325 6.14442V9.94676C8.86325 10.4029 8.49682 10.7729 8.04507 10.7729C7.59312 10.7729 7.22689 10.4029 7.22689 9.94676V6.14442Z' fill='black'/%3E%3C/svg%3E");
  }
}
