@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Thin.woff2') format('woff2'),
    url('../fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans.woff2') format('woff2'),
    url('../fonts/BloggerSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-Bold.woff2') format('woff2'),
    url('../fonts/BloggerSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-Italic.woff2') format('woff2'),
    url('../fonts/BloggerSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-Light.woff2') format('woff2'),
    url('../fonts/BloggerSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.woff2') format('woff2'),
    url('../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.woff2') format('woff2'),
    url('../fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Italic.woff2') format('woff2'),
    url('../fonts/Inter-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-MediumItalic.woff2') format('woff2'),
    url('../fonts/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ThinItalic.woff2') format('woff2'),
    url('../fonts/Inter-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-LightItalic.woff2') format('woff2'),
    url('../fonts/Inter-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-Medium.woff2') format('woff2'),
    url('../fonts/BloggerSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-BlackItalic.woff2') format('woff2'),
    url('../fonts/Inter-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-LightItalic.woff2') format('woff2'),
    url('../fonts/BloggerSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/BloggerSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-BoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Blogger Sans';
  src: url('../fonts/BloggerSans-MediumItalic.woff2') format('woff2'),
    url('../fonts/BloggerSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Andantino script';
  src: url('../fonts/Andantinoscript.woff2') format('woff2'),
    url('../fonts/Andantinoscript.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
