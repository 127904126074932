.section-about {

  h1 {
    margin-bottom: 52px;
    font-size: 58px;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 42px;
      font-size: 42px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      font-size: 36px;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
  }

  &__text-block {
    padding-top: 45px;

    @include media-breakpoint-down(lg) {
      padding-top: 18px;
      padding-bottom: 80px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 37px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__img {

    @include media-breakpoint-down(lg) {
      padding-left: 43px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-bottom: 40px;
    }
  }

  &__footer {
    padding: 100px 0;
    background-color: $primary;
    background-image: url(../img/section-about-footer-bg1.svg),
      url(../img/section-about-footer-bg2.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, right bottom;

    @include media-breakpoint-down(md) {
      padding: 50px 0 75px;
      background-size: 30%, 30%;
    }

    @include media-breakpoint-down(sm) {
      background-image: none;
    }

    blockquote {
      margin: 0 auto;
      padding: 0;
      display: flex;
      max-width: 720px;
      flex-direction: column;
      align-items: center;
      font-size: 26px;
      line-height: 1.3;
      font-weight: 300;
      text-align: center;
      color: $white;
      background: transparent;

      @include media-breakpoint-down(md) {
        max-width: 470px;
        font-size: 18px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      &:before {
        position: static;
        margin-bottom: 20px;
        display: block;
        width: 47px;
        height: 31px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='47' height='31' viewBox='0 0 47 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.765 0C29.867 0 25.21 4.85859 25.21 10.8511C25.21 16.8435 30.054 21.7021 35.952 21.7021C36.011 21.7021 36.1 21.694 36.159 21.693C33.827 23.5797 30.898 24.7166 27.692 24.7166C26.217 24.7166 25.03 25.9318 25.03 27.4294C25.03 28.927 25.979 30.1422 27.454 30.1422C37.923 30.1411 46.195 21.4877 46.195 10.8511C46.195 10.849 46.195 10.847 46.195 10.8439C46.195 4.85453 41.661 0 35.765 0Z' fill='white'/%3E%3Cpath d='M10.75 0C4.852 0 0.187 4.85859 0.187 10.8511C0.187 16.8435 5.027 21.7021 10.926 21.7021C10.985 21.7021 11.072 21.694 11.131 21.693C8.799 23.5797 5.869 24.7166 2.663 24.7166C1.188 24.7166 0 25.9318 0 27.4294C0 28.927 0.964 30.1422 2.439 30.1422C12.908 30.1411 21.195 21.4877 21.195 10.8511C21.195 10.849 21.195 10.847 21.195 10.8439C21.195 4.85453 16.646 0 10.75 0Z' fill='white'/%3E%3C/svg%3E");
      }
    }
  }
}
