.select-custom {
  position: relative;
  z-index: 11;
  width: 100%;

  select {
    width: 100%;
  }

  &__dropdown {
    position: relative;
    z-index: 9;
  }

  .select2-container {
    display: block;
  }

  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__display:after {
    margin-right: 4px;
    display: inline-block;
  }
}
