.map {
  position: relative;
  width: 100%;

  &__container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    max-width: 1290px;

    @include media-breakpoint-down(lg) {
      max-width: 960px;
    }

    @include media-breakpoint-down(md) {
      max-width: 740px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      max-width: 100%;
    }
  }

  &__row {
    margin: 0;
  }

  .select-custom {
    position: absolute;
    top: 20px;
    left: 30px;
    width: 100%;
    max-width: 270px;

    @include media-breakpoint-down(md) {
      left: 20px;
    }

    @include media-breakpoint-down(sm) {
      top: 40px;
      right: 20px;
      max-width: calc(100% - 40px);
    }
  }

  .map__place {
    overflow: hidden;
    height: 410px;
    border-radius: 14px;

    @include media-breakpoint-down(sm) {
      height: 470px;
      border-radius: 0;
    }
  }
}
