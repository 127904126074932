.search {
  display: none;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__wrap {
    position: relative;
    min-height: 80px;

    @include media-breakpoint-down(lg) {
      min-height: 60px;
    }
  }

  &__input {
    position: absolute;
    z-index: 1;
    padding-right: 60px;
    padding-left: 98px;
    width: 100%;
    height: 80px;
    font-size: 24px;
    line-height: 1;
    font-weight: 300;
    color: $dark;
    background: $body-bg
      url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 14C10.2857 15.2857 12.5199 15.1429 12.5199 15.1429C16.4254 15.1429 19.5914 11.9769 19.5914 8.07143C19.5914 4.16599 16.4254 1 12.5199 1C8.61451 1 5.44852 4.16599 5.44852 8.07143L5.44852 10.7102C5.44852 13.5256 4.2266 16.2023 2.09948 18.0467L1 19' stroke='%23C7CCD1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      40px center no-repeat;
    border: none;
    border-radius: 40px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, .1);

    @include placeholder($secondary);

    @include media-breakpoint-down(lg) {
      height: 60px;
      background: $body-bg
        url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 14C10.2857 15.2857 12.5199 15.1429 12.5199 15.1429C16.4254 15.1429 19.5914 11.9769 19.5914 8.07143C19.5914 4.16599 16.4254 1 12.5199 1C8.61451 1 5.44852 4.16599 5.44852 8.07143L5.44852 10.7102C5.44852 13.5256 4.2266 16.2023 2.09948 18.0467L1 19' stroke='%23C7CCD1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        30px center no-repeat;
      border-radius: 30px;
    }

    @include media-breakpoint-down(xs) {
      padding-left: 30px;
      font-size: 20px;
      background: $body-bg;
    }
  }

  &__results {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    padding: 89px 30px 19px;
    display: none;
    background: $body-bg;

    @include media-breakpoint-down(lg) {
      padding-top: 69px;
      padding-bottom: 25px;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 69px;
      padding-bottom: 25px;
    }

    @include media-breakpoint-down(xs) {
      padding: 54px 14px 19px;
      text-align: center;
    }
  }

  &__category {
    position: relative;
    padding: 26px 0;

    @include media-breakpoint-down(xs) {
      padding: 29px 0 20px;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      content: '';
      background: $light;

      @include media-breakpoint-down(lg) {
        right: -30px;
        left: -30px;
      }

      @include media-breakpoint-down(xs) {
        right: -14px;
        left: -14px;
      }
    }

    &:last-of-type {
      margin-bottom: 20px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 16px;
        padding-bottom: 30px;
      }
    }
  }

  &__item {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
      align-items: flex-start;
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {

      .search__name {
        color: $primary;
      }
    }
  }

  &__img {
    margin-right: 23px;
    width: 100%;
    max-width: 46px;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-right: 20px;
    }
  }

  &__name {
    font-size: 24px;
    line-height: 26px;
    font-weight: 300;
    color: $dark;

    @include transition;

    @include media-breakpoint-down(xs) {
      font-size: 20px;
      line-height: 22px;
    }

    small {
      margin-top: 6px;
      display: block;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $secondary;

      @include media-breakpoint-down(xs) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}
