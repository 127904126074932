.menu-aside {
  margin: 0;
  padding: 0 0 40px;
  list-style-type: none;

  @include media-breakpoint-down(md) {
    display: inline-flex;
    //justify-content: center;
    //overflow: auto;
  }

  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }

  li {
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding-right: 30px;
      padding-bottom: 10px;
      white-space: nowrap;
      border-bottom: 1px solid $light;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-right: 0;

      @include media-breakpoint-down(xs) {
        padding-right: 30px;
      }
    }

    a {
      font-size: 20px;
      line-height: 1.2;
      color: $secondary;

      @include transition;

      @include media-breakpoint-down(md) {
        padding-bottom: 10px;
        font-size: 16px;
        border-bottom: 2px solid transparent;
      }

      &.is-active,
      &:hover {
        color: $dark;

        @include media-breakpoint-down(md) {
          border-color: $primary;
        }
      }
    }
  }

  &__wrap {

    @include media-breakpoint-down(md) {
      overflow: auto;
      text-align: center;
    }

    @include media-breakpoint-down(xs) {
      margin-right: -15px;
      width: calc(100% + 15px);
    }
  }
}
