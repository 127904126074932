.video-block {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
    flex-direction: column;
  }

  &--reverse {

    .video-block__card {

      @include media-breakpoint-down(sm) {
        order: 1;
      }
    }

    .video-block__desc {

      @include media-breakpoint-down(sm) {
        order: 2;
      }
    }
  }

  &__desc {
    width: 100%;
    max-width: calc(50% - 35px);

    @include media-breakpoint-down(lg) {
      max-width: calc(50% - 15px);
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 42px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 100%;
    }
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }

  &__card {
    width: 100%;
    max-width: calc(50% - 35px);

    @include media-breakpoint-down(lg) {
      max-width: calc(50% - 15px);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      max-width: 100%;
    }
  }
}
