.share {
  margin-top: 37px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    margin-top: 23px;
    flex-direction: column;
    align-items: flex-end;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 13px;
  }

  &__left {
    padding-top: 35px;
    width: 100%;
    max-width: 285px;

    @include media-breakpoint-down(lg) {
      padding-top: 27px;
      max-width: calc(100% - 99px);
      order: 2;
    }

    @include media-breakpoint-down(md) {
      max-width: calc(100% - 81px);
    }

    @include media-breakpoint-down(sm) {
      padding-top: 13px;
      max-width: 100%;
    }
  }

  &__right {
    padding-top: 35px;
    display: flex;
    width: 100%;
    max-width: 770px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $light2;

    @include media-breakpoint-down(lg) {
      padding-top: 50px;
      max-width: calc(100% - 139px);
      order: 1;
    }

    @include media-breakpoint-down(md) {
      max-width: calc(100% - 121px);
    }

    @include media-breakpoint-down(sm) {
      padding-top: 23px;
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__soc {

    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 29px;
    }

    &-text {
      margin-right: 11px;
      font-family: $font-secondary;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      letter-spacing: .05em;
      text-transform: uppercase;
      color: $secondary;
    }
  }

  &__next {
    min-width: 220px;
  }

  &__back {
  }

  .btn-soc {

    @include media-breakpoint-down(sm) {
      margin-right: 6px !important;
    }
  }

  .btn-share {
    padding-left: 0;
  }

  &--recipe {
    margin-top: 0;
    max-width: 83.33333%;

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    .share__left {
      padding-top: 73px;
      max-width: 216px;

      @include media-breakpoint-down(lg) {
        padding-top: 27px;
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 13px;
        max-width: 100%;
      }
    }

    .share__right {
      padding-top: 73px;
      padding-left: 80px;
      max-width: 850px;
      border-top: none;

      @include media-breakpoint-down(lg) {
        padding-top: 50px;
        padding-left: 0;
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 42px;
        max-width: 100%;
      }
    }
  }
}
