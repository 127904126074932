.section-shops {
  padding: 95px 0;
  background: $grey2;

  @include media-breakpoint-down(md) {
    padding: 80px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 46px 0 50px;
  }

  &__head {

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    .h1 {
      margin-bottom: 15px;
      font-size: 58px;
      color: $dark;

      @include media-breakpoint-down(lg) {
        font-size: 42px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 36px;
      }
    }

    p {
      font-size: 15px;
      color: $grey3;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }

  &__link {
    position: relative;
    z-index: 1;
    margin-top: -24px;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    a {
      font-family: $font-secondary;
      font-size: 16px;
      color: $dark2;

      @include transition;

      &:hover {
        color: $primary;
      }
    }
  }
}
