.card-slide {
  padding: 24px 25px 4px;
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 20px;

  @include transition;

  @include media-breakpoint-down(md) {
    padding: 20px;
    flex-direction: column;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px 15px;
  }

  &:hover {
    filter: drop-shadow(0 5px 4px rgba(143, 147, 153, .15));
  }

  &__img {
    min-width: 170px;

    @include media-breakpoint-down(md) {
      min-width: inherit;
    }
  }

  &__desc {
    margin-left: 50px;

    @include media-breakpoint-down(lg) {
      margin-left: 20px;
    }
  }

  &__title {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 32px;
    line-height: 1;
    font-weight: 300;
    color: $dark;

    @include transition;

    &:hover {
      color: $primary;
    }
  }

  &__text {
    margin-bottom: 16px;
    font-family: $font-secondary;
    font-size: 15px;
    line-height: 1.4;
    color: $secondary;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }

    span {
      color: $dark;
    }
  }
}
