.header {
  position: sticky;
  z-index: 100;
  top: 0;

  @include media-breakpoint-down(lg) {
    position: sticky;
  }

  .btn-search {
    position: absolute;
    top: 33px;
    right: 3px;

    @include transition;

    @include media-breakpoint-down(lg) {
      position: static;
    }
  }

  .btn-menu {
  }

  &__container {
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__logo {
    position: absolute;
    top: 20px;
    left: 50%;
    display: inline-block;
    transition: $transition-base;
    transform: translateX(-50%);

    @include media-breakpoint-down(lg) {
      top: 12px;
    }

    @include media-breakpoint-down(sm) {
      top: 6px;
    }

    img {

      @include media-breakpoint-down(lg) {
        width: 111px;
      }

      @include media-breakpoint-down(lg) {
        max-width: 100px;
      }
    }
  }

  &--home {
    margin-bottom: -79px;
    height: 79px;

    @include media-breakpoint-down(lg) {
      margin-bottom: -80px;
      height: 80px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: -60px;
      height: 60px;
    }
  }

  &.is-sticky {
    position: sticky;
    background: $white;
    box-shadow: 0 4px 14px rgba(67, 70, 74, .1);

    .menu__main > li > a {
      padding: 30px 0;
    }

    .header__logo {
      top: 5px;

      @include media-breakpoint-down(lg) {
        top: 12px;
      }

      @include media-breakpoint-down(sm) {
        top: 6px;
      }
    }

    .btn-search {
      top: 21px;
    }
  }
}
