.search-address {
  margin-bottom: 50px;
  padding: 11px 14px 11px 30px;
  display: flex;
  align-items: center;
  background: $grey2;
  border-radius: 70px;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }

  &__input {
    padding-left: 13px;
    flex-grow: 1;
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
    background: transparent;
    border: 0;
    padding-right: 15px;

    @include placeholder(rgba($dark, .4));

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &__btn {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: #fff url(../img/search-zoom.svg) no-repeat 13px 13px;
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(241, 239, 235, .5);
    @include transition;
    
    &:hover {
      opacity: .8;
    }
  }
}
