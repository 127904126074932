.text-block {

  &--center {
    padding-right: 150px;
    padding-left: 70px;

    @include media-breakpoint-down(lg) {
      padding-right: 55px;
      padding-left: 58px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 40px;
      display: flex;
      font-size: 26px;
      line-height: 1.3;

      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
        font-size: 18px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:before {
        margin-top: 17px;
        margin-right: 30px;
        display: block;
        width: 6px;
        min-width: 6px;
        height: 6px;
        content: '';
        background: $primary;
        border-radius: 100%;

        @include media-breakpoint-down(md) {
          margin-top: 9px;
          margin-right: 28px;
        }

        @include media-breakpoint-down(sm) {
          margin-right: 24px;
        }
      }
    }
  }

  h5 {
    font-family: $font-secondary;
    font-size: 22px;
    line-height: 31px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .p-lg {
    font-family: $font-secondary;
    line-height: 27px;
    font-weight: 300;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.text-center {

  img {
    margin-bottom: 50px;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 37px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }
}

blockquote {
  position: relative;
  margin: 15px 10px 28px -60px;
  padding: 63px 90px 70px 130px;
  font-family: $font-family-base;
  font-size: 29px;
  line-height: 38px;
  font-weight: 300;
  background: $grey2;
  border-radius: 14px;

  @include media-breakpoint-down(lg) {
    margin: 0 -62px 10px;
    padding: 49px 120px;
    font-size: 24px;
    line-height: 31px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 50px;
    padding: 84px 40px 35px;
    font-size: 17px;
    line-height: 22px;
  }

  &:before {
    position: absolute;
    top: 68px;
    left: 48px;
    width: 49px;
    height: 30px;
    content: '';
    background: url("data:image/svg+xml,%3Csvg width='47' height='30' viewBox='0 0 47 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.7643 0C29.8663 0 25.2093 4.782 25.2093 10.68C25.2093 16.578 30.0533 21.36 35.9513 21.36C36.0103 21.36 36.0993 21.352 36.1583 21.351C33.8263 23.208 30.8973 24.327 27.6913 24.327C26.2163 24.327 25.0293 25.523 25.0293 26.997C25.0293 28.471 25.9783 29.667 27.4533 29.667C37.9223 29.666 46.1943 21.149 46.1943 10.68C46.1943 10.678 46.1943 10.676 46.1943 10.673C46.1943 4.778 41.6603 0 35.7643 0Z' fill='%230057A5'/%3E%3Cpath d='M10.75 0C4.852 0 0.187 4.782 0.187 10.68C0.187 16.578 5.027 21.36 10.926 21.36C10.985 21.36 11.072 21.352 11.131 21.351C8.799 23.208 5.869 24.327 2.663 24.327C1.188 24.327 0 25.523 0 26.997C0 28.471 0.964 29.667 2.439 29.667C12.908 29.666 21.195 21.149 21.195 10.68C21.195 10.678 21.195 10.676 21.195 10.673C21.195 4.778 16.646 0 10.75 0Z' fill='%230057A5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      top: 49px;
      left: 39px;
    }

    @include media-breakpoint-down(sm) {
      top: 35px;
      left: 40px;
    }
  }
}
