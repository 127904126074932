/* Стандартный transition для hover-анимаций */

@mixin transition {
  transition: $transition-base;
}

/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
@mixin placeholder($color) {

  &::-webkit-input-placeholder {
    color: $color;

    @include transition;
  }

  &::-moz-placeholder {
    color: $color;

    @include transition;
  }

  &:-ms-input-placeholder {
    color: $color;

    @include transition;
  }
}

/* Fix for Fancybox */

@function max($numbers...) {

  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {

  @return m#{i}n(#{$numbers});
}
