@import 'bootstrap';

@import 'mixins';

/* Plugins */
@import 'plugins/tiny-slider';

@import 'plugins/jquery.fancybox_fix';

@import 'plugins/perfect-scrollbar';

@import 'plugins/select2.min';

@import 'plugins/plugins-rewrite';

/* Global styles */
@import 'global/global-fonts';

@import 'global/global-typo';

@import 'global/global-scaffolding';

/* Blocks */

@import './src/blocks/footer/footer';

@import './src/blocks/section-news/section-news';

@import './src/blocks/section-shops/section-shops';

@import './src/blocks/recipes/recipes';

@import './src/blocks/recipe-card/recipe-card';

@import './src/blocks/like/like';

@import './src/blocks/slider-controls/slider-controls';

@import './src/blocks/section/section';

@import './src/blocks/card-video/card-video';

@import './src/blocks/front/front';

@import './src/blocks/mouse/mouse';

@import './src/blocks/header/header';

@import './src/blocks/menu/menu';

@import './src/blocks/product/product';

@import './src/blocks/search/search';

@import './src/blocks/front-page/front-page';

@import './src/blocks/breadcrumbs/breadcrumbs';

@import './src/blocks/link-navs/link-navs';

@import './src/blocks/card/card';

@import './src/blocks/product-card/product-card';

@import './src/blocks/card-slide/card-slide';

@import './src/blocks/section-cards/section-cards';

@import './src/blocks/contacts/contacts';

@import './src/blocks/map-contacts/map-contacts';

@import './src/blocks/way/way';

@import './src/blocks/section-form/section-form';

@import './src/blocks/select-custom/select-custom';

@import './src/blocks/brand/brand';

@import './src/blocks/map/map';

@import './src/blocks/privilege/privilege';

@import './src/blocks/video-block/video-block';

@import './src/blocks/section-about/section-about';

@import './src/blocks/text-block/text-block';

@import './src/blocks/menu-aside/menu-aside';

@import './src/blocks/tags/tags';

@import './src/blocks/card-news/card-news';

@import './src/blocks/share/share';

@import './src/blocks/slider-news/slider-news';

@import './src/blocks/filters/filters';

@import './src/blocks/section-recipe/section-recipe';

@import './src/blocks/slider-recipes/slider-recipes';

@import './src/blocks/block-404/block-404';

@import './src/blocks/thx/thx';

@import './src/blocks/popup-form/popup-form';

@import './src/blocks/circle/circle';

@import './src/blocks/front-slider/front-slider';

@import './src/blocks/map-addresses/map-addresses';

@import './src/blocks/address/address';

@import './src/blocks/search-address/search-address';
