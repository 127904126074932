.product {
  position: relative;
  padding-top: 150px;
  padding-bottom: 200px;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;

  @include media-breakpoint-down(lg) {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  @include media-breakpoint-down(md) {
    background-image: none !important;
  }

  &__item {

    &--pic-sm {
      //      @include media-breakpoint-down(md) {
      //        width: calc(50% + 60px)!important;
      //        margin-left: -30px!important;
      //        padding-left: 30px;
      //        padding-right: 30px;
      //        opacity: 0;
      //      }
      //
      //      &.tns-slide-active {
      //
      //        @include media-breakpoint-down(md) {
      //          opacity: 1;
      //        }
      //      }

      .product__img {
        margin-top: -45px;
        margin-right: 10px;
        margin-left: -70px;
        width: 360px;

        @include media-breakpoint-down(lg) {
          margin-left: -80px;
          width: 315px;
          height: 350px;
        }

        @include media-breakpoint-down(md) {
          position: relative;
          margin-top: 60px;
          margin-right: 10px;
          margin-left: -30px;
          width: 330px;
        }

        @include media-breakpoint-down(sm) {
          margin: 20px auto 0;
          max-width: 100%;
          height: 200px;
        }

        img {

          @include media-breakpoint-down(md) {
            position: absolute;
            left: 50%;
            max-width: 140%;
            transform: translateX(-50%);
          }
        }

        &--next {
          margin-top: 0;
          margin-right: 10px;
          margin-left: -10px;
          width: 300px;

          @include media-breakpoint-down(md) {
            margin-left: 0;
          }

          .product__title {
            margin: -10px auto 0;
            padding-top: 0;
            max-width: 80%;
            font-size: 32px;
            line-height: 1.1;
            text-align: center;
          }
        }
      }

      .product__right {

        @include media-breakpoint-down(md) {
          padding-top: 0;
        }
      }
    }
  }

  &__img {
    width: 410px;
    flex-shrink: 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-left: -90px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -50px;
    }

    @include media-breakpoint-down(md) {
      width: 315px;
      height: 350px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 310px;
    }

    img {
      max-height: 100%;
    }

    .product__title {
      display: none;
    }
  }

  &__img--next {

    @include media-breakpoint-up(lg) {
      margin-bottom: 138px;
      margin-left: 0;
      width: 242px;
      height: 310px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    .product__title {
      padding-top: 20px;
      display: block;
      visibility: visible;
    }
  }

  &__right {
    width: 350px;
    max-width: calc(100% - 415px);
    flex-shrink: 0;
    align-self: start;

    @include media-breakpoint-up(lg) {
      padding-top: 25px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-left: 12px;
      width: 100%;
      max-width: calc(100% - 315px);
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
      text-align: center;
    }
  }

  &__row {
    position: relative;
    height: 525px;
    align-items: center;

    @include media-breakpoint-down(md) {
      left: 0;
      width: 100%;
      height: auto;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  &__title {
    visibility: hidden;
    font-size: 35px;
    line-height: 1.1;
    text-align: center;

    small {
      margin-top: 10px;
      display: block;
      font-family: $font-secondary;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: .17em;
      text-transform: uppercase;
      color: #8f9399;
    }
  }

  &__desc {
    padding-bottom: 50px;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    .h1 {
      margin-bottom: 15px;
      display: inline-block;
      color: $body-color;

      @include media-breakpoint-down(md) {
        font-size: 42px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 36px;
      }

      &:hover {
        text-decoration: none;
        color: $body-color;
      }
    }

    .text-secondary {
      margin-bottom: 25px;
      opacity: .7;

      @include media-breakpoint-down(sm) {
        margin-bottom: 17px;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }

  &__desc-subheader {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    line-height: 1;
    letter-spacing: .17em;
    text-transform: uppercase;
    color: #8f9399;
  }

  &__prop {
    display: flex;
    align-items: center;

    img {
      max-width: 40px;
      flex-shrink: 0;
    }
  }

  &__prop-text {
    padding-left: 10px;
    font-size: 29px;
    line-height: 1;
    font-weight: 300;

    @include media-breakpoint-down(sm) {
      margin-bottom: 7px;
      padding-left: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: baseline;
      font-size: 12px;
      line-height: 1.4;
      font-weight: 500;
      text-align: center;
    }

    .p-xs {
      opacity: .4;
      font-family: $font-secondary;
      font-weight: 500;
      text-transform: uppercase;
      color: #43464a;

      @include media-breakpoint-down(sm) {
        padding-right: 5px;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  .slider-controls {
    position: absolute;
    z-index: 2;
    //    bottom: 84px;
    bottom: 40px;
    left: 590px;

    @include media-breakpoint-down(lg) {
      left: 587px;
    }
  }

  .tns-ovh {

    @include media-breakpoint-up(lg) {
      overflow: visible;
    }

    @include media-breakpoint-down(md) {
      overflow: visible;
    }
  }

  .tns-nav {
    position: relative;
    z-index: 3;
    margin-top: 30px;
    margin-bottom: 100px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 50px;
    }
  }
}

.product__item.animation-out {
  animation-direction: normal;

  .product__desc {

    @include media-breakpoint-up(lg) {
      animation-name: text-animation-out;
      animation-duration: .8s;
      animation-fill-mode: both;
    }
  }

  .product__img {

    img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation-out;
        animation-duration: .8s;
        animation-fill-mode: both;
      }
    }
  }

  .product__img--next {

    img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation2-out;
        animation-duration: .8s;
        animation-fill-mode: both;
      }
    }
  }

  .product__title {

    @include media-breakpoint-up(lg) {
      animation-name: title-animation-out;
      animation-duration: .8s;
      animation-fill-mode: both;
    }
  }

  &.animation-back {

    .product__title {

      @include media-breakpoint-up(lg) {
        animation-name: title-animation;
        animation-direction: reverse;
      }
    }

    .product__img img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation2-reverse-out;
        animation-direction: normal;
      }
    }

    .product__img--next img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation;
        animation-direction: reverse;
      }
    }

    .product__desc {

      @include media-breakpoint-up(lg) {
        animation-name: text-animation;
        animation-direction: reverse;
      }
    }
  }
}

.product__item.animation-in {

  .product__desc {

    @include media-breakpoint-up(lg) {
      animation-name: text-animation;
      animation-duration: .8s;
      animation-fill-mode: both;
    }
  }

  .product__img {

    img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation2;
        animation-duration: .8s;
        animation-fill-mode: both;
      }
    }
  }

  .product__img--next {

    img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation;
        animation-duration: .8s;
        animation-fill-mode: both;
      }
    }
  }

  .product__title {

    @include media-breakpoint-up(lg) {
      visibility: visible;
      animation-name: title-animation;
      animation-duration: .8s;
      animation-fill-mode: both;
    }
  }

  &.animation-back {

    .product__title {

      @include media-breakpoint-up(lg) {
        animation-name: title-animation-out;
        animation-direction: reverse;
      }
    }

    .product__img img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation-out;
        animation-direction: reverse;
      }
    }

    .product__img--next img {

      @include media-breakpoint-up(lg) {
        animation-name: img-animation2-reverse;
        animation-direction: normal;
      }
    }

    .product__desc {

      @include media-breakpoint-up(lg) {
        animation-name: text-animation-out;
        animation-direction: reverse;
      }
    }
  }
}

@keyframes img-animation {

  from {
    opacity: 0;
    transform: translateX(-80%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes img-animation2 {

  from {
    transform: translateX(-80%) scale(.7);
  }

  to {
    transform: none;
  }
}

@keyframes img-animation2-reverse {

  from {
    transform: translate(70%, 12%) scale(1.65);
  }

  to {
    transform: none;
  }
}

@keyframes img-animation2-out {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes img-animation2-reverse-out {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate(-70%, -12%) scale(.7);
  }
}

@keyframes img-animation-out {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(80%);
  }
}

@keyframes title-animation {

  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes title-animation-out {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes text-animation {

  from {
    opacity: 0;
    transform: translateY(-80%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes text-animation-out {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(80%);
  }
}
