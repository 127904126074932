.recipe-card {
  position: relative;
  padding-bottom: 15px;
  border-radius: 20px;

  @include media-breakpoint-down(md) {
    //    border-radius: 10px;
    padding-bottom: 0;
  }

  &:hover {

    .recipe-card__img {
      box-shadow: 0 5px 3px rgba(143, 147, 153, .3);

      img {
        width: 110%;
        height: 110%;
      }
    }
  }

  &--blue {

    .recipe-card__img {

      &:after {
        background: linear-gradient(180deg, rgba(56, 100, 110, 0) 0%, #38646e 91.67%);
      }
    }
  }

  .like {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 20px;

    @include media-breakpoint-down(md) {
      top: 20px;
    }

    &:after {
      display: none;
    }
  }

  &__img {
    position: relative;
    padding-top: 140%;
    display: block;
    overflow: hidden;
    border-radius: 20px;

    @include transition;

    @include media-breakpoint-down(md) {
      padding-top: 132%;
      //      border-radius: 10px;
    }

    &:after {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(151, 132, 120, .3) 2.08%,
        rgba(151, 132, 120, 0) 43.6%,
        #7e6650 100%
      );
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      object-fit: cover;
      object-position: center center;

      @include transition;
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    padding: 0 30px 30px;
    display: flex;
    flex-direction: column;
  }

  &__time {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: $white;

    &:before {
      margin-right: 10px;
      display: block;
      width: 17px;
      height: 19px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='19' viewBox='0 0 17 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.5' cy='10.5' r='8' stroke='white'/%3E%3Cline x1='8.5' y1='5' x2='8.5' y2='11' stroke='white'/%3E%3Cline x1='8.5' y1='-2.18557e-08' x2='8.5' y2='2' stroke='white'/%3E%3Cline x1='10' y1='0.5' x2='7' y2='0.5' stroke='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 29px;
    color: $white;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.tns-inner {

  .recipe-card {

    .like {
      right: 50px;

      @include media-breakpoint-down(md) {
        right: 30px;
      }
    }
  }
}
