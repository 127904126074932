.slider-controls {
  position: relative;
  padding: 12px 0 10px;
  width: 133px;
  background: $white;
  border-radius: 40px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, .1);

  @include media-breakpoint-up(lg) {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  &__count {
    display: flex;

    span {
      font-size: 20px;
      line-height: 1;
      color: $dark;
    }

    small {
      font-size: 12px;
      line-height: 1;
      color: $grey4;
    }
  }

  &__btns {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:focus,
    &:active {
      outline: none;
    }

    button {
      position: absolute;
      top: 0;
      display: flex;
      width: 40px;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: #c7ccd1;
      background: none;
      transition: $transition-base;

      &:hover {
        color: $primary;
      }
    }

    &--prev {
      left: 10px;
    }

    &--next {
      right: 10px;
    }
  }

  &--vertical {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 100px;
    padding: 0;
    width: 60px;
    height: 180px;
    background: transparent;
    border: 2px solid $white;
    border-radius: 50px;
    transform: translateY(-50%);

    @include media-breakpoint-down(xl) {
      right: 75px;
      width: 45px;
      height: 133px;
    }

    @include media-breakpoint-down(lg) {
      right: 15px;
    }

    .slider-controls__count {

      span {
        font-size: 26px;
        color: $white;

        @include media-breakpoint-down(xl) {
          font-size: 20px;
        }
      }

      small {
        font-size: 16px;
        color: $white;

        @include media-breakpoint-down(xl) {
          font-size: 12px;
        }
      }
    }

    .slider-controls__btns {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      button {
        width: 100%;
        height: 40px;
        color: #fff;

        &:hover {
          opacity: .8;
          color: #fff;
        }
      }

      &--prev {
        top: 10px;
        left: 0;
      }

      &--next {
        top: auto;
        right: 0;
        bottom: 10px;
      }
    }
  }
}
