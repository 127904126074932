.section-form {
  margin-top: 203px;
  padding: 20px 0 100px;
  background: $grey2;

  @include media-breakpoint-down(lg) {
    margin-top: 123px;
    padding-top: 40px;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 0 80px;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 0 60px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 96px;
  }

  h2 {
    margin-bottom: 30px;
    font-size: 42px;
    line-height: 1.1;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 1;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  &__label {
    position: absolute;
    top: 23px;
    left: 25px;
    margin: 0;
    font-family: $font-secondary;
    font-size: 16px;
    color: $secondary;
    cursor: text;

    @include transition;

    &.is-active {
      top: 15px;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__input {

    &.is-active,
    &:focus {
      padding: 25px 25px 5px;
    }

    &:focus ~ label {
      top: 15px;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__terms {
    font-family: $font-secondary;
    font-size: 14px;
    color: $secondary;

    a {
      color: $primary;

      @include transition;

      &:hover {
        color: $primary-hover;
      }
    }
  }

  textarea.form-control {
    resize: none;
  }
}
