.link-navs {
  padding: 60px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(md) {
    padding: 50px 0 40px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    padding: 40px 0 20px;
    overflow: auto;
    justify-content: flex-start;
  }

  &__item {
    margin: 0 10px;
    padding: 8px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    color: $secondary;
    background: $grey2;
    border-radius: 30px;

    @include transition;

    &.is-active,
    &:hover {
      color: $white;
      background: $primary;

      .link-navs__icon {

        img {
          filter: brightness(100%);
          opacity: 1;
        }
      }
    }
  }

  &__icon {
    margin-right: 8px;
    width: 34px;

    img {
      filter: brightness(60%);
      opacity: .5;

      @include transition;
    }
  }
}
