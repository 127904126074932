.card {
  display: flex;
  width: 100%;
  height: 550px;

  @include media-breakpoint-down(lg) {
    height: 440px;
  }

  @include media-breakpoint-down(md) {
    height: 400px;
  }

  @include media-breakpoint-down(sm) {
    height: 432px;
  }

  @include media-breakpoint-down(xs) {
    height: 440px;
  }

  &__box {
    position: relative;
    padding: 41px 35px 43px;
    width: 100%;
    text-align: center;
    background: $body-bg;
    border: 1px solid #f3f3f1;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(241, 239, 235, .5);
    transition: z-index .1s cubic-bezier(.25, .1, .25, 1) .5s,
      background .3s cubic-bezier(.25, .1, .25, 1),
      box-shadow .3s cubic-bezier(.25, .1, .25, 1),
      border-color .3s cubic-bezier(.25, .1, .25, 1);

    @include media-breakpoint-down(lg) {
      padding: 30px 10px 15px;
    }

    @include media-breakpoint-down(xs) {
      padding: 36px 10px 33px;
    }

    &:hover {
      z-index: 5;
      background: $grey2;
      border-color: $grey2;
      box-shadow: none;
      align-self: flex-start;

      .card__img {
        margin-bottom: 20px;
        padding-top: 82%;
        transform: translateY(-6px);

        @include media-breakpoint-down(md) {
          padding-top: 102%;
        }

        @include media-breakpoint-down(xs) {
          padding-top: 0;
        }
      }

      .card__img > img {

        @include media-breakpoint-down(xs) {
          max-height: 210px;
        }
      }

      .card__hover {
        position: static;
        padding-top: 18px;
        padding-right: 0;
        padding-left: 0;
        visibility: visible;
        max-height: 320px;
        opacity: 1;
        transform: none;
      }

      .card__iso {
        top: 33px;
        right: 64%;
        visibility: visible;
        width: 70px;
        height: 70px;
        opacity: 1;

        @include media-breakpoint-down(lg) {
          right: 55%;
        }

        @include media-breakpoint-down(md) {
          top: 19px;
          right: 59%;
          width: 60px;
          height: 60px;
        }

        @include media-breakpoint-down(xs) {
          right: 48vw;
        }
      }
    }
  }

  &__img {
    position: relative;
    margin-bottom: 20px;
    padding-top: 115%;

    @include transition;

    @include media-breakpoint-down(md) {
      padding-top: 128%;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
      padding-top: 0;
    }

    & > img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include transition;

      @include media-breakpoint-down(xs) {
        position: relative;
        width: auto;
        height: auto;
        max-height: 298px;
        object-fit: fill;
      }
    }
  }

  &__iso {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    visibility: hidden;
    width: 120px;
    height: 120px;
    opacity: 0;

    @include transition;

    img {
      width: 100%;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-size: 42px;
    line-height: 46px;
    font-weight: 300;
    color: $dark;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 28px;
    }

    small {
      margin-top: 14px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: .17em;
      text-transform: uppercase;
      color: $secondary;

      @include media-breakpoint-down(lg) {
        font-size: 11px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
  }

  &__text {
    margin-bottom: 27px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    line-height: 19px;
    color: $secondary;
  }

  &__hover {
    position: absolute;
    left: 0;
    padding: 0 41px;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    opacity: 0;
    transition-property: max-height, opacity;

    @include transition;

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @include media-breakpoint-down(xs) {
      padding-right: 36px;
      padding-left: 36px;
    }
  }

  &__props {
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__prop {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    font-size: 25px;
    line-height: 100%;
    font-weight: 300;
    text-align: left;
    color: $secondary;

    small {
      margin-bottom: 5px;
      font-family: 'Inter', sans-serif;
      font-size: 10px;
      line-height: 14px;
      font-weight: 500;
      letter-spacing: .1em;
      text-transform: uppercase;
    }

    &-item {
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 15px;
      }
    }
  }

  &__icon {
    margin-right: 13px;
  }

  &--dark {

    .card__box {

      &:hover {
        background: #fff;
        border: 1px solid #f3f3f1;
      }
    }
  }

  &--rectangle {
    height: 416px;

    @include media-breakpoint-down(lg) {
      height: 320px;
    }

    @include media-breakpoint-down(md) {
      height: 285px;
    }

    @include media-breakpoint-down(sm) {
      height: 302px;
    }

    @include media-breakpoint-down(xs) {
      //      height: auto;
      height: 310px;
    }

    .card__img {
      padding-top: 56%;

      @include media-breakpoint-down(xs) {
        margin-bottom: -35px;
        padding-top: 0;
      }

      img {
        left: 50%;
        width: 123%;
        max-width: none;
        height: auto;
        max-height: none;
        transform: translateX(-50%);
        object-position: top center;

        @include media-breakpoint-down(xs) {
          width: 110%;
        }
      }
    }

    .card__box {

      &:hover {

        .card__img {
          padding-top: 56%;

          @include media-breakpoint-down(xs) {
            margin-bottom: -35px;
            padding-top: 0;
          }
        }

        .card__hover {

          @include media-breakpoint-down(lg) {
            max-height: none;
          }
        }
      }
    }
  }
}
