.address {
  margin-bottom: 30px;
  padding: 22px 46px 25px;
  display: block;
  width: 100%;
  border: 1px solid rgba(224, 223, 220, .5);
  border-radius: 20px;
  box-shadow: 0 4px 4px rgba(241, 239, 235, .5);

  @include transition;

  @include media-breakpoint-down(lg) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
    padding: 20px;
  }

  &__title {
    font-size: 32px;
  }

  &__content {
    display: flex;
    font-family: $font-secondary;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }

    &:before {
      margin-top: 3px;
      margin-right: 7px;
      display: block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      content: '';
      background: url(../img/address-pin.svg) no-repeat center;
      background-size: contain;
    }
  }
}
