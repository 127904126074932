.thx {
  padding: 80px 15px;
  display: flex;
  min-height: calc(100vh - 126px);
  justify-content: center;
  align-items: center;
  background: transparent url(../img/thx-bg.png) center bottom no-repeat;
  background-size: cover;

  @include media-breakpoint-down(lg) {
    min-height: calc(100vh - 80px);
  }

  @include media-breakpoint-down(md) {
    min-height: 535px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 494px;
  }

  &__panel {
    padding: 40px 65px 75px;
    max-width: 470px;
    text-align: center;
    background: $white;
    border-radius: 40px;
    box-shadow: 0 34px 70px -10px rgba(0, 0, 0, .05);

    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: 435px;
      background: transparent;
      box-shadow: none;
    }

    p {
      margin: 0 0 30px;
      padding: 0;
      font-family: $font-secondary;
      font-size: 14px;
      color: $secondary;

      a {
        color: $primary;

        @include transition;

        &:hover {
          color: $primary-hover;
        }
      }
    }

    .btn {
      width: 290px;
    }
  }
}
