.brand {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 170px;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;

  @include media-breakpoint-down(md) {
    padding: 20px 28px;
    height: 127px;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 50px;
    height: auto;
  }

  &:hover {

    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  img {
    filter: grayscale(100%);
    max-width: 90%;
    max-height: 90%;
    opacity: .5;

    @include transition;
  }

  &__row {

    @include media-breakpoint-down(lg) {
      margin-right: -5px;
      margin-left: -5px;
    }
  }

  &__text {
    margin-top: 27px;
    font-size: 26px;
    line-height: 1;
    font-weight: 300;
    color: $light-grey3;

    @include media-breakpoint-down(md) {
      margin-top: 17px;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &--card {
    border: 1px solid rgba(224, 223, 220, .5);
    border-radius: 20px;
    box-shadow: 0 4px 4px rgba(241, 239, 235, .5);

    @include media-breakpoint-down(sm) {
      padding: 20px 15px;
      height: 100%;
      border-radius: 10px;
    }

    @include media-breakpoint-down(xs) {
      border-radius: 6px;
    }

    &:hover {

      img {
        opacity: 1;
      }
    }

    img {
      opacity: .6;
    }
  }

  &--card-partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    img {
      opacity: 1;

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
