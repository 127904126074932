.section-news {
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 80px 0 50px;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0 40px;
  }

  &__left {
    padding-right: 62px !important;
    border-right: 1px solid $light;

    @include media-breakpoint-down(md) {
      padding-right: 0 !important;
      padding-bottom: 46px;
      border-right: 0 none;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }
  }

  &__right {
    padding-left: 67px !important;

    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
      display: flex;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__card {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__img {
    margin-right: 49px;
    display: block;
    width: 100%;
    max-width: 281px;
    flex-shrink: 0;

    @include media-breakpoint-down(md) {
      margin-right: 36px;
      max-width: 322px;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 28px;
    }

    img {

      @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__desc {

    @include media-breakpoint-down(md) {
      padding-bottom: 23px;
      border-bottom: 1px solid $light;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }

    a.h5 {
      padding-bottom: 15px;
      display: inline-block;
      font-family: $font-secondary;

      @include transition;

      @include media-breakpoint-down(md) {
        padding-bottom: 10px;
      }
    }

    .p {
      padding-bottom: 30px;

      @include media-breakpoint-down(sm) {
        padding-bottom: 20px;
      }
    }

    .section-news__date {

      @include media-breakpoint-down(md) {
        padding-bottom: 10px;
      }
    }
  }

  &__date {
    padding-bottom: 15px;
    font-family: $font-secondary;
    font-size: 13px;
    color: $light-grey;

    @include media-breakpoint-down(sm) {
      padding-bottom: 10px;
    }
  }

  &__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $light;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding-bottom: 50px;
      width: calc(50% - 15px);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      width: 100%;
    }

    .h5 {
      font-family: $font-secondary;
    }

    &--first {

      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    &--last {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0 none;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        margin-left: 15px;
        padding-bottom: 50px;
        border-bottom: 1px solid $light;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        margin-left: 0;
        padding-bottom: 0;
        border: 0 none;
      }
    }

    a {
      display: inline-block;

      @include transition;
    }
  }
}
