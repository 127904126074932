.contacts {
  display: flex;
  justify-content: space-around;
  text-align: center;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &__left {
    width: 100%;
    max-width: 346px;

    @include media-breakpoint-down(md) {
      max-width: 200px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
      max-width: 100%;
    }
  }

  &__name {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: rgba($dark, .34);
  }

  &__tel {
    margin-top: 18px;
    display: inline-block;
    font-size: 42px;
    line-height: 46px;
    font-weight: 300;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
      line-height: 100%;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 6px;
      font-size: 42px;
      line-height: 46px;
    }
  }

  &__center {
    width: 100%;
    max-width: 346px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      max-width: 100%;
    }
  }

  &__adr {
    margin: 17px auto 15px;
    max-width: 264px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
      line-height: 20px;
    }

    @include media-breakpoint-down(sm) {
      margin: 7px auto 15px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__map-links {
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 5px;
    }
  }

  &__right {
    width: 100%;
    max-width: 346px;

    @include media-breakpoint-down(md) {
      max-width: 200px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &__mail {
    position: relative;
    margin-top: 19px;
    display: inline-block;
    font-size: 42px;
    line-height: 100%;
    color: $primary;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 7px;
      font-size: 28px;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: -7px;
      left: 0;
      content: '';
      border-bottom: 1px dashed #0057a5;
    }
  }
}
