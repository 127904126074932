.recipes {
  padding: 100px 0 90px;
  overflow: hidden;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 70px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }

  &__head {

    a.h1 {
      margin-bottom: 17px;
      display: inline-block;
      font-size: 58px;

      @include transition;

      @include media-breakpoint-down(lg) {
        font-size: 42px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 36px;
      }
    }

    .text-secondary {
      margin-bottom: 25px;
      font-size: 15px;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        font-size: 14px;
      }
    }

    .btn-primary--recipe {
      margin-bottom: 124px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 80px;
      }
    }
  }

  &__slider {

    @include media-breakpoint-down(xs) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .tns-ovh {

    @include media-breakpoint-down(xs) {
      visibility: visible;
    }
  }

  .tns-nav {
    margin: 30px 0 40px;
  }
}
