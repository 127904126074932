.block-404 {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 128px;
  display: flex;
  width: 100%;
  min-height: 722px;
  justify-content: center;
  align-items: flex-end;
  text-align: center;

  @include media-breakpoint-down(md) {
    min-height: 536px;
  }

  @include media-breakpoint-down(xs) {
    padding-bottom: 110px;
    min-height: 490px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .56;
    content: '';
    background: url(../img/img-bg-404.jpg) center top no-repeat;
    background-size: cover;
    filter: grayscale(1);

    @include media-breakpoint-down(xs) {
      background-position: 61% top;
    }
  }

  &__text-img {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fbf9f6, .9);

    span {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 60px;
      width: 100%;
      height: 100%;
      font-size: 338px;
      line-height: 100%;
      font-weight: 500;
      background: url(../img/img-bg-404.jpg) center top no-repeat;
      -webkit-background-clip: text;
      background-size: cover;
      -webkit-text-fill-color: transparent;

      @include media-breakpoint-down(lg) {
        padding-top: 0;
      }

      @include media-breakpoint-down(md) {
        padding-top: 70px;
        font-size: 160px;
      }

      @include media-breakpoint-down(xs) {
        padding-top: 80px;
        font-size: 130px;
        background-position: 61% top;
      }
    }
  }

  h1 {
    position: relative;
    z-index: 3;
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 46px;
    color: #000000;

    @include media-breakpoint-down(lg) {
      font-size: 36px;
      line-height: 100%;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
      font-size: 28px;
    }
  }

  &__text {
    position: relative;
    z-index: 3;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 20px;
    color: $secondary;
  }

  &__btn {
    position: relative;
    z-index: 3;
    min-width: 298px;
  }
}
