@import '../../node_modules/bootstrap/scss/functions';

@import '../../node_modules/bootstrap/scss/variables';

@import '../../node_modules/bootstrap/scss/mixins';

/* Custom variables */

// ------------GRID------------
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);
$container-max-widths: (
  sm: 570px,
  md: 770px,
  lg: 990px,
  xl: 1320px,
);
// ------------SPACER---------
$spacer: 10px;
$spacers: (
  0: 0,
  5: (
    $spacer * .5,
  ),
  10: (
    $spacer * 1,
  ),
  15: (
    $spacer * 1.5,
  ),
  20: (
    $spacer * 2,
  ),
  25: (
    $spacer * 2.5,
  ),
  30: (
    $spacer * 3,
  ),
  35: (
    $spacer * 3.5,
  ),
  40: (
    $spacer * 4,
  ),
  45: (
    $spacer * 4.5,
  ),
  50: (
    $spacer * 5,
  ),
  55: (
    $spacer * 5.5,
  ),
  60: (
    $spacer * 6,
  ),
  65: (
    $spacer * 6.5,
  ),
  70: (
    $spacer * 7,
  ),
  75: (
    $spacer * 7.5,
  ),
  80: (
    $spacer * 8,
  ),
  85: (
    $spacer * 8.5,
  ),
  90: (
    $spacer * 9,
  ),
  95: (
    $spacer * 9.5,
  ),
  100: (
    $spacer * 10,
  ),
  105: (
    $spacer * 10.5,
  ),
  110: (
    $spacer * 11,
  ),
  115: (
    $spacer * 11.5,
  ),
  120: (
    $spacer * 12,
  ),
  125: (
    $spacer * 12.5,
  ),
  130: (
    $spacer * 13,
  ),
);

// ------------VARIABLES-------

//colors
$colors: ();

$primary: #0057a5;
$secondary: #8f9399;
$light: #f1f2f3;
$light2: #dee3e9;
$dark: #43464a;
$dark2: #425261;
$darkblue: #00417a;
$grey: #8e96a2;
$grey2: #fbf9f6;
$grey3: #747d85;
$grey4: #a9b2ba;
$grey5: #c7ccd1;
$grey6: #abb0b5;
$grey7: #faf9f5;
$grey8: #6c757d;
$light-grey: #828383;
$light-grey2: #ced4da;
$light-grey3: #b6b2ac;
$light-grey4: #e6e7e8;
$red: #e21608;

$theme-colors: ();

$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

//global
$body-bg: #fff;
$body-color: #43464a;
$line-height-sm: 1.2;
$line-height-lg: 1.4;

//fonts
$font-family-base: 'Blogger Sans', sans-serif;

//text
$text-muted: $gray-600;

//link
$link-color: $body-color;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: none;
$emphasized-link-hover-darken-percentage: 15%;

//transition
$transition-base: .3s cubic-bezier(.25, .1, .25, 1);
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;

//inputs
$input-focus-box-shadow: 0 4px 4px rgba(241, 239, 235, .5);
$input-box-shadow: 0 4px 4px rgba(241, 239, 235, .5);
$input-border-radius: 14px;
$input-font-size: 16px;
$input-height: 70px;
$input-padding-x: 25px;
$input-font-family: 'Inter', sans-serif;
$input-focus-border-color: $primary;
$input-border-color: #e0dfdc;
$input-height-sm: 50px;
$input-border-radius-sm: 25px;
$input-font-size-sm: 14px;
$input-padding-x-sm: 25px;
//button
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-border-radius: 45px;
$btn-line-height: 36px;
$btn-font-size: 13px;
$btn-padding-x: 20px;
$btn-font-family: 'Inter', sans-serif;
$btn-font-weight: 500;

//border
//$border-width: 1px;
//$border-color: #eee;
//$border-radius: 10px;
//$border-radius-lg: .3rem;
//$border-radius-sm: .2rem;
//checkbox
//$custom-control-indicator-size: 1.2rem;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $border-color;
$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-focus-border-color: $border-color;

/* Project variables */
$primary-hover: #0063bd;
$font-secondary: 'Inter', sans-serif;

/* Imports */

//@import '../../node_modules/bootstrap/scss/root';

@import '../../node_modules/bootstrap/scss/reboot';

//@import '../../node_modules/bootstrap/scss/type';

//@import '../../node_modules/bootstrap/scss/images';

@import '../../node_modules/bootstrap/scss/grid';

@import '../../node_modules/bootstrap/scss/forms';

@import '../../node_modules/bootstrap/scss/buttons';

@import '../../node_modules/bootstrap/scss/transitions';

@import '../../node_modules/bootstrap/scss/custom-forms';

@import '../../node_modules/bootstrap/scss/nav';

@import '../../node_modules/bootstrap/scss/utilities';

//@import '../../node_modules/bootstrap/scss/print';
