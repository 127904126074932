.footer {

  &__head {
    padding: 45px 0;

    @include media-breakpoint-down(md) {
      padding: 33px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 55px 0;
    }
  }

  &__subs-text {

    @include media-breakpoint-down(sm) {
      padding-bottom: 22px;
    }
  }

  &__form {
    position: relative;

    input {
      padding: 16px 240px 16px 28px;
      width: 100%;
      height: 50px;
      font-size: 13px;
      color: $white;
      background: rgba($darkblue, .3);
      border: 0 none;
      border-radius: 75px;

      @include placeholder(#fff);

      @include transition;

      &:focus {

        @include placeholder(transparent);
      }

      @include media-breakpoint-down(md) {
        padding: 16px 70px 16px 24px;
      }
    }

    button[type='submit'] {
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-up(lg) {
        width: 220px;
        height: 53px;
      }
    }
  }

  &__bottom {
    padding: 27px 0 37px;
    border-top: 1px solid rgba($grey, .1);

    @include media-breakpoint-down(md) {
      padding: 26px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0 32px;
    }
  }

  &__menu {
    margin: 0 -37px;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;

    @include media-breakpoint-down(lg) {
      margin: 0;
      justify-content: space-between;
    }

    li {
      margin: 0 37px;

      @include media-breakpoint-down(lg) {
        margin: 0;
      }
    }

    a {
      font-family: $font-secondary;
      font-size: 16px;

      @include transition;
    }
  }

  &__call {

    @include media-breakpoint-up(xl) {
      margin-left: -30px;
      min-width: calc(100% + 30px);
    }

    @include media-breakpoint-down(lg) {
      margin-top: 6px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 11px;
    }
  }

  &__phone {
    padding-top: 24px;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      text-align: center;
    }

    a.footer__phone {
      padding-top: 18px;
      display: inline-block;

      @include media-breakpoint-down(md) {
        padding-top: 10px;
        font-size: 42px;
      }

      @include transition;
    }
  }

  &__contacts {
    padding-top: 24px;
    font-family: $font-secondary;
    font-weight: 300;

    @include media-breakpoint-up(xl) {
      margin-left: -40px;
      min-width: calc(100% + 40px);
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .p-md {
      padding-top: 13px;
    }
  }

  &__social {
    padding-top: 24px;

    @include media-breakpoint-up(xl) {
      margin-left: -30px;
      min-width: calc(100% + 30px);
    }

    @include media-breakpoint-down(lg) {
      padding-top: 14px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 21px;
      display: flex;
      justify-content: center;
    }

    a {

      &:last-of-type {

        @include media-breakpoint-down(sm) {
          margin-right: 0 !important;
        }
      }
    }
  }

  &__copyright {
    margin-right: 70px;
    font-family: $font-secondary;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;
      color: #8f9399;
    }
  }

  &__policy {
    font-family: $font-secondary;

    @include media-breakpoint-down(sm) {
      margin: 5px 0;
      font-size: 13px;
      color: #8f9399;
    }
  }

  &__studio {
    font-family: $font-secondary;
    color: #b4b5b7;

    @include media-breakpoint-down(sm) {
      font-size: 13px;
      color: #8f9399;
    }
  }

  &__section-link {

    @include media-breakpoint-down(md) {
      color: rgba(#43464a, .34);
    }
  }
}
