.way {

  @include media-breakpoint-down(xs) {
    margin: 0 15px;
  }

  .slider-controls {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }

  .tns-slider {
    display: flex;
  }

  &__item {
    //    margin-right: 5px;
    min-height: 410px;
    background: $primary;
    border-radius: 20px;

    @include media-breakpoint-down(md) {
      min-height: 372px;
    }
  }

  &__img {
    position: relative;

    img {
      width: 100%;
      max-height: 177px;
      border-radius: 19px 19px 21px 21px;
    }
  }

  &__wrap {
    padding: 20px 20px 100px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 80px;
    }
  }

  &__title {
    margin-bottom: 7px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    color: #fff;
  }
}
