.front-slider {

  &__wrapper {
    margin-top: -18px;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }

    .section__wave-scroll {

      @include media-breakpoint-down(xs) {
        bottom: -15px !important;
      }
    }
  }

  &__item {
    width: 100%;
    min-height: 57.5vh;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(sm) {
      min-height: 300px;
    }
  }
}
