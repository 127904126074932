.tags {
  display: flex;
  flex-flow: row wrap;

  &--center {
    justify-content: center;

    a {

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
      }

      &:last-child {

        @include media-breakpoint-down(xs) {
          margin-right: 0 !important;
        }
      }
    }
  }

  &--border {
    padding: 40px 0;
    border-top: 1px solid $light-grey4;
    border-bottom: 1px solid $light-grey4;
  }

  a {
    margin-right: 30px;
    font-family: $font-secondary;
    font-size: 16px;
    color: $secondary;

    @include transition;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }

    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active,
    &:hover {
      color: $primary;
    }
  }
}
