.section {

  &__wave-scroll {
    overflow: hidden;
    width: 100%;
    height: 12vw;
    max-height: 218px;

    @include media-breakpoint-down(md) {
      height: 65px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      transition: $transition-base;
      transform-origin: center bottom;
    }
  }

  &__wave {
    overflow: hidden;
    width: 100%;
    height: 198px;

    @include media-breakpoint-down(lg) {
      height: 140px;
    }

    @include media-breakpoint-down(md) {
      height: 65px;
    }

    .wave {
      position: absolute;
      top: 0;
      width: 20000px;
      height: 100%;
      opacity: .7;
      background: url(../img/wave-2.svg) repeat-x;
      background-size: auto 100%;
      transform: translate3d(0, 0, 0);
      animation: wave 150s cubic-bezier(.36, .45, .63, .53) infinite;

      @include media-breakpoint-down(sm) {
        width: 10000px;
      }

      &:nth-of-type(2) {
        top: 25px;
        opacity: 1;
        animation: wave 150s cubic-bezier(.36, .45, .63, .53) -.125s infinite,
          swell 21s ease -1.25s infinite;
      }
    }
  }

  &--static-wave {
    position: relative;
    background: $grey2;

    .wave {
      position: absolute;
      right: 0;
      bottom: 100%;
      left: 0;
      height: 135px;
      background: url(../img/wave-gray.svg) repeat-x;
      background-size: auto 100%;

      @include media-breakpoint-down(lg) {
        height: 70px;
      }

      @include media-breakpoint-down(xs) {
        height: 56px;
      }
    }
  }

  &--wave-bottom {
    position: relative;
    overflow: hidden;

    .section__wave,
    .section__wave-scroll {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
    }

    .section__wave-scroll {
      bottom: -140px;
      transform: scale(1, 1);
      transform-origin: 50% 100%;
      will-change: transform;

      @include media-breakpoint-down(lg) {
        bottom: -30px;
      }

      @include media-breakpoint-down(xs) {
        bottom: -15px;
      }
    }
  }

  &--color-wave {
    background: #fbf9f6;
  }

  &--wave-revert {

    img {
      margin-top: -1px;
      transform: rotate(180deg);
    }
  }
}

@keyframes wave {

  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -10600px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}
