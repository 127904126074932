.privilege {
  padding: 40px 50px 40px 40px;
  display: flex;
  min-height: 210px;
  background: $body-bg;
  border-radius: 20px;
  box-shadow: 0 4px 64px rgba(241, 239, 235, .5);

  @include media-breakpoint-down(lg) {
    padding: 33px 30px 30px;
    min-height: 302px;
    flex-direction: column;
    align-items: center;
  }

  @include media-breakpoint-down(xs) {
    margin: 0 -15px;
  }

  &__icon {
    margin-right: 40px;
    width: 80px;
    text-align: center;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &__desc {
    flex: 1;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 13px;
    font-size: 32px;
    line-height: 100%;

    @include media-breakpoint-down(lg) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: rgba($dark, .5);
  }
}
